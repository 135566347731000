import { mapState, mapActions } from 'vuex'

import debounce from 'lodash/debounce'
import Accordion from './Accordion/Accordion.vue'
import Spinner from '@/components/lib/Spinner/Spinner.vue'

export default {
  components: {
    Accordion,
    Spinner
  },
  data () {
    return {
      searchWords: this.$route.query.queryString,
      selectedTopic: null,
      selectTopicIsOpen: false
    }
  },
  watch: {
    '$route.query.queryString': {
      async handler () {
        await this.searchQuestions({ locale: this.language, query: this.$route.query.queryString })
      },
      immediate: true
    },
    searchWords: debounce(async function () {
      await this.$router.replace({ path: 'faq', query: { queryString: this.searchWords } })
    }, 300, { trailing: true }),
    language () {
      this.clearSearch()
    },
    filteredTopics: {
      handler () {
        if (this.filteredTopics && this.filteredTopics.length > 0) {
          this.selectedTopic = this.filteredTopics[0]
        } else {
          this.selectedTopic = null
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      topics: state => state.faq.topics,
      searchedQuestions: state => state.faq.searchedQuestions
    }),
    filteredTopics () {
      const searchQuestions = this.searchedQuestions.filter(q => !!q)
      const filtered = this.searchWords && this.searchWords.length > 0
        ? this.topics.reduce((accumulator, current) => {
          const questions = searchQuestions.filter(q => q.topic === current._id)
          if (questions.length > 0) {
            accumulator.push({
              ...current,
              questions
            })
          }
          return accumulator
        }, [])
        : this.topics
      return filtered
    }
  },
  async created () {
    await Promise.all([
      this.getTopics(),
      this.searchQuestions({ locale: 'en' })
    ])
  },
  methods: {
    ...mapActions({
      getTopics: 'faq/getTopics',
      searchQuestions: 'faq/searchQuestions'
    }),
    clearSearch () {
      this.searchWords = ''
    },
    selectTopic (topic) {
      this.selectedTopic = topic
      this.selectTopicIsOpen = false
    },
    openSelectTopic () {
      this.selectTopicIsOpen = !this.selectTopicIsOpen
    }
  }
}
