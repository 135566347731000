import AccordionItem from '../AccordionItem/AccordionItem.vue'

export default {
  components: {
    AccordionItem
  },
  props: {
    content: Array
  },
  computed: {
    itemExpanded () {
      return this.content[0]._id
    }
  }
}
