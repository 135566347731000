
export default {
  props: {
    title: String,
    itemExpanded: String,
    id: String
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    isExpanded () {
      return this.itemExpanded === this.id
    }
  },
  methods: {
    expandList () {
      this.expanded = !this.expanded
    }
  },
  mounted () {
    this.expanded = this.isExpanded
  },
  watch: {
    itemExpanded: {
      async handler () {
        this.expanded = this.isExpanded
      },
      immediate: true
    }
  }
}
